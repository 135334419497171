<template>
  <b-row >
    <v-snackbar v-model="snackbar" :timeout="10000" :color="snackbarColor" class="d-none">
      {{ snackbarMessage }}
      <b-button color="black" text @click="snackbar = false">
        Kapat
      </b-button>
    </v-snackbar>
    <b-col class="pt-0">
      <v-select v-model="open_customer_info.campaign" :options="campaigns" :placeholder="lang('t_campaigns')" ></v-select>
      <b-button @click="open_customer" style="float: right;" class="btn-success mt-1">{{lang("t_VSPROpenCustomer")}}</b-button>
    </b-col>
  </b-row>
</template>
<script>
import {BButton, BCard, BCardText, BLink , BRow, BFormFile, BCol, BFormGroup} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
   components: {
    BButton,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormFile,
  },
    props: {
      project: '',
    },
    data: () => ({
      lang: _l(window.localStorage.getItem('language') || "tr"),
      campaigns: ['kamp1','kamp2','kamp3'],
      open_customer_info: {
        campaign: ''
      },
      snackbar: false,
      snackbarMessage: '',
      snackbarColor: '',
    }),
/*
    methods: {
      GetCampaign: async function () {
        var response = (
          await this.$http.get(`/Campaign`, {
            headers: {
              authorization: this.$store.state.user.token,
            },
          })
        ).data;

        this.campaigns = response.filter(e => e.project == this.project);
      },
      open_customer: async function () {
        if (
          this.open_customer_info.campaign.length == 0
        ) {
          this.snackbarMessage = globalThis._lang("t_pleaseFillTheAllNecessaryAreas");
          this.snackbarColor = 'warning';
          this.snackbar = true;
          return;
        }
        console.log("open_customer_info", this.open_customer_info);
        var response = (
          await this.$http.put(
            `/OpenCustomer`,
            {
              collection: `${this.project}_customers`,
              campaign: `${this.open_customer_info.campaign}`
            },
            {
              headers: {
                authorization: this.$store.state.user.token,
              },
            }
          )
        ).data;

        if (response.ok != 1) {
          this.snackbarMessage = globalThis._lang("t_thereWasAProblemActivatingCustomers");
          this.snackbarColor = 'error';
          this.snackbar = true;
        } else {
          this.snackbarMessage = `${response.matchedCount} ${globalThis._lang("t_customersHaveBeenSuccessfullyActivated")}`;
          this.snackbarColor = 'success';
          this.snackbar = true;
        }
        console.log('open_customer', response);
      },
    },
    computed: {},

    mounted: function () {
      this.GetCampaign();
    },
    */
  };
</script>
