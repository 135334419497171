<template>
  <div>
    <div class="mx-2">
      <!--  v-if="columns.length > 0"  -->
      <!-- <v-select
        v-model="close_customer_info.column"
        :options="columns"
        :placeholder="lang('t_matchingColumn')"
      ></v-select> -->
      <b-form-file
        v-model="files"
        @input="read_excel"
        :placeholder="lang('t_uploadFileOnClick')"
        drop-placeholder="Dosya yükle..."
        id="extension"
        accept=".xlsx, .xls"
      />
      <b-form-checkbox class="mt-1" v-model="close_customer_info.set_passive">
        {{ lang("t_setPassiveWhenNoProduct") }}
      </b-form-checkbox>
    </div>
    <div class="border-top my-1 d-flex align-items-center">
      <b-button class="ml-auto mr-1 mt-1" variant="secondary">
        {{ lang("t_cancel") }}
      </b-button>
      <b-button
        class="mt-1 mr-2"
        @click="close_customer"
        style="float: right"
        variant="primary"
        >{{ lang("t_save") }}</b-button
      >
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BLink,
  BRow,
  BFormFile,
  BCol,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormFile,
    BFormCheckbox,
  },
  props: {
    project: "",
  },
  data: () => ({
    lang: _l(window.localStorage.getItem("language") || "tr"),
    columns: ["Müşteri No", "TCKN"],
    agents: [],
    close_customer_info: {
      // column: "",
      data: "",
      set_passive: false
    },
    files: "",
  }),

  methods: {
    closeModal() {

      globalThis.crm.dynamic_modal = false;
    },
    async read_excel() {
      console.log("this.$xlsx.", this.$xlsx);
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = this.$xlsx.read(bstr, { type: "binary" });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = this.$xlsx.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
        });
        var _data = this.xlsxToJson(data);
        this.close_customer_info.data = _data;
        var asd = Object.keys(_data[0]);
        // console.log(this.excel.data);
      };
      reader.readAsBinaryString(this.files);
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },
    close_customer: async function () {
      if (
        this.close_customer_info.data == "" || this.close_customer_info.data == null
      ) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseFillTheAllNecessaryAreas"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      var response = (
        await this.$http_in.put(`crm/v1/CloseProduct`, {
          collection: `${this.project}_customers`,
          close_customer_info: this.close_customer_info,
        })
      ).data;

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      console.log("close_product", response);
    },
  },
  computed: {},

  mounted: function () { },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
