<template>
  <b-row>
    <v-snackbar v-model="snackbar" :timeout="10000" :color="snackbarColor" class="d-none">
      {{ snackbarMessage }}
      <b-button color="black" text @click="snackbar = false"> Kapat </b-button>
    </v-snackbar>
    <b-col class="pt-0">
        <b-form-file
            :placeholder="lang('t_uploadFileOnClick')"
            drop-placeholder="Dosya yükle..."
            id="extension"
            accept=".xlsx, .xls"
            no-drop
          />

      <b-button @click="upload" style="float: right" class="btn-success mt-1">
        <span v-if="!is_progress">Yükle</span>
        <v-progress-circular
          small
          v-if="is_progress"
          indeterminate
          color="white"
        ></v-progress-circular>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {BButton, BCard, BCardText, BLink , BRow, BFormFile, BCol, BFormGroup} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
   components: {
    BButton,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormFile,
  },
  props: {
    project: ''
  },
  data: () => ({
    lang: _l(window.localStorage.getItem('language') || "tr"),
    columns: [],
    agents: [],
    excel: {
      data: [],
      keys: [],

    },
    is_progress: false,
    files: '',
    assign_agent_info: {
      column: '',
      data: '',
      agent: '',
    },
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
  }),
/*
  methods: {
    async upload() {
      this.is_progress = true;

      let formData = new FormData();
      formData.append('files', this.files, this.files.name);
      formData.append('selectedProject', this.project);

      var response = await this.$http.post('/UploadFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: this.$store.state.user.token,
          project: this.project,
        },
      });
      this.is_progress = false;
      this.snackbarMessage = globalThis._lang("t_fileHasBeenImportedSuccessfully");
      this.snackbarColor = "success";
      this.snackbar = true;

    },
  },
  computed: {
  },

  mounted: function () {

  },
  */

};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>